<template>
  <div class="content" v-loading.fullscreen.lock="fullscreenLoading">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>角色管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content_li">
      <div class="top-search1">
        <div class="flexRow justifyContentSpac">
          <div class="flexRow">
            <div class="flexRow alignItems">
              <div class="search-title">角色名称：</div>
              <el-input v-model="search.name" placeholder="搜索"></el-input>
            </div>
            <div class="flexRow alignItems">
              <div class="search-title">角色唯一标识：</div>
              <el-input v-model="search.authority" placeholder="搜索"></el-input>
            </div>
            <el-button type="primary" @click="handleSearch">搜索</el-button>
            <el-button @click="initSearch">重置</el-button>
          </div>
          <el-button type="primary" v-if="roleAdd" @click="addDislog">新增角色</el-button>
        </div>
      </div>

      <div class="contant flex flex_row">
        <div class="leftContant">
          <el-tree :props="defaultProps" ref="asyncTree" node-key="id" :load="loadNode" lazy>
            <span class="custom-tree-node" slot-scope="{ node, data }" @click="updataTree(data)">
              <span>{{ data.name }}</span>
              <span v-if="data.folder">
                <el-dropdown @command="handleCommand($event, data)">
                  <i class="el-icon-setting"></i>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-if="roleAdd" command="add">新增菜单</el-dropdown-item>
                    <el-dropdown-item v-if="roleUpd" command="edit">编辑菜单</el-dropdown-item>
                    <el-dropdown-item v-if="roleDel" command="del">删除菜单</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </span>
            </span>
          </el-tree>
        </div>
        <div class="rightContant">
          <el-table
            border
            :data="tableData"
            style="width: 100%;margin-bottom: 20px;"
            :header-cell-style="this.utils.getRowClass"
          >
            <el-table-column align="center" prop="name" label="角色名称"></el-table-column>
            <el-table-column align="center" prop="authority" label="角色唯一标识"></el-table-column>
            <!--          <el-table-column align="center" prop="pathId" label="岗位"></el-table-column>-->
            <!--          <el-table-column align="center" label="备注">-->
            <!--            <template slot-scope="scpoe">-->
            <!--              <div class="contennt-desc">{{ scpoe.row.remarks }}</div>-->
            <!--            </template>-->
            <!--          </el-table-column>-->
            <el-table-column align="center" prop="updateDate" label="修改时间"></el-table-column>
            <el-table-column align="center" prop="address" label="操作">
              <template slot-scope="scope">
                <div class="flex flex_row flex_ju_center">
                  <div class="border_center_left" v-if="roleUpd" @click="updateData(scope.row)">编辑</div>
                  <div class="border_center_left" v-if="roleDel" @click="delData(scope.row.id)">删除</div>
                  <div
                    class="border_center_left"
                    v-if="!scope.row.folder && roleGrantMenu"
                    @click="menuSort(scope.row)"
                  >分配权限</div>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <!-- <div class="hr" v-if="!isFolder"></div> -->
          <!-- <div class="contantTop flex flex_end">
          <div v-if="!isFolder" class="tipButton addQx flex flex_ju_center flex_center" @click="addDislogQx = true">
            新增角色
          </div>
          </div>-->

          <!-- <el-table v-if="!isFolder" :data="syData" style="width: 100%;margin-bottom: 20px;" header-cell-class-name="tableClass" border>
          <el-table-column align="center" prop="name" label="角色名称"> </el-table-column>
          <el-table-column align="center" prop="authority" label="角色唯一标识"> </el-table-column>
          <el-table-column align="center" prop="updateDate" label="修改时间"> </el-table-column>
          <el-table-column align="center" prop="address" label="操作">
            <template slot-scope="scope">
              <div class="flex flex_row flex_ju_center">
                <div class="color_font" @click="menuSort(scope.row)">分配权限</div>
                <div class="border_center" @click="updateData(scope.row)">编辑</div>
                <div class="color_font" @click="delData(scope.row.id)">删除</div>
              </div>
            </template>
          </el-table-column>
          </el-table>-->
        </div>
      </div>

      <el-dialog
        title="新增"
        :visible.sync="addMenuDislog"
        width="30%"
        :close-on-click-modal="false"
        @close="addhandleClose('ruleForm')"
      >
        <div class="dialong-content">
          <div class="dialong-title">角色名称</div>
          <el-input maxlength="15" placeholder="请输入菜单名称" v-model="addForm.name"></el-input>
        </div>
        <div class="dialong-content">
          <div class="dialong-title">菜单类型</div>
          <el-radio-group v-model="addForm.folder">
            <el-radio :label="1">文件夹</el-radio>
            <el-radio :label="2">菜单</el-radio>
          </el-radio-group>
        </div>
        <div class="dialong-content">
          <div class="dialong-title">角色唯一标识</div>
          <el-input maxlength="15" placeholder="请输入角色唯一标识" v-model="addForm.authority"></el-input>
        </div>
        <!--      <div class="dialong-content">-->
        <!--        <div class="dialong-title">岗位</div>-->
        <!--        <el-select v-model="addForm.postId" placeholder="请选择岗位">-->
        <!--          <el-option-->
        <!--              v-for="item in postList"-->
        <!--              :key="item.id"-->
        <!--              :label="item.name"-->
        <!--              :value="item.id">-->
        <!--          </el-option>-->
        <!--        </el-select>-->
        <!--      </div>-->
        <div class="dialong-content">
          <div class="dialong-title">排序</div>
          <el-input placeholder="请输入排序" type="number" v-model="addForm.sort"></el-input>
        </div>
        <!--      <div class="dialong-content">-->
        <!--        <div class="dialong-title">备注</div>-->
        <!--        <el-input type="textarea" placeholder="请输入备注" v-model="addForm.remarks"-->
<!--                          :autosize="{ minRows: 4, maxRows: 6}"-->
<!--                          maxlength="20" show-word-limit-->
        <!--        />-->
        <!--      </div>-->
        <span slot="footer" class="dialog-footer">
          <el-button @click="addhandleClose">取 消</el-button>
          <el-button :loading="loading" type="primary" @click="addMenu()">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 分配权限 -->
      <el-dialog
        title="角色权限分配"
        :visible.sync="menuProp"
        width="30%"
        :close-on-click-modal="false"
        @close="menuProp = false"
      >
        <el-tree :props="prop" ref="Tree" node-key="id" :load="loadNodeMenu" lazy>
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <el-checkbox
              @change="changeMenuList(data)"
              v-model="data.hasRole"
              :label="data.name"
              :value="data.id"
            ></el-checkbox>
          </span>
        </el-tree>
      </el-dialog>

      <el-dialog
        title="编辑角色"
        :visible.sync="editMenuDislog"
        width="30%"
        :close-on-click-modal="false"
        @close="editMenuDislog = false"
      >
        <div class="dialong-content">
          <div class="dialong-title">角色名称</div>
          <el-input maxlength="15" placeholder="请输入菜单名称" v-model="form.name"></el-input>
        </div>
        <div class="dialong-content">
          <div class="dialong-title">菜单类型</div>
          <el-radio-group v-model="form.folder">
            <el-radio :label="1">文件夹</el-radio>
            <el-radio :label="2">菜单</el-radio>
          </el-radio-group>
        </div>
        <div class="dialong-content">
          <div class="dialong-title">角色唯一标识</div>
          <el-input placeholder="请输入角色唯一标识" v-model="form.authority"></el-input>
        </div>
        <!--      <div class="dialong-content">-->
        <!--        <div class="dialong-title">岗位</div>-->
        <!--        <el-select v-model="form.postId" placeholder="请选择岗位">-->
        <!--          <el-option-->
        <!--              v-for="item in postList"-->
        <!--              :key="item.id"-->
        <!--              :label="item.name"-->
        <!--              :value="item.id">-->
        <!--          </el-option>-->
        <!--        </el-select>-->
        <!--      </div>-->

        <div class="dialong-content">
          <div class="dialong-title">排序</div>
          <el-input placeholder="请输入排序" type="number" v-model="form.sort"></el-input>
        </div>

        <!--      <div class="dialong-content">-->
        <!--        <div class="dialong-title">备注</div>-->
        <!--        <el-input type="textarea" placeholder="请输入备注" v-model="form.remarks"-->
        <!--                  :autosize="{ minRows: 4, maxRows: 6}"-->
        <!--                  maxlength="20" show-word-limit-->
        <!--        />-->
        <!--      </div>-->
        <span slot="footer" class="dialog-footer">
          <el-button @click="editMenuDislog = false">取 消</el-button>
          <el-button :loading="loading" type="primary" @click="editMenu()">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      currentPage: 1,
      total: 0,
      isFolder: false,
      isDel: true,
      fullscreenLoading: false,
      addMenuDislog: false, //新增角色
      editMenuDislog: false, //编辑角色
      loading: false,
      defaultCheckedKeys: [], //菜单已选择得节点id列表
      menuList: [], //菜单列表
      menuProp: false,
      search: {
        name: "",
        authority: ""
      },
      prop: {
        children: "zones",
        label: "name"
      }, //树形结构参数
      defaultProps: {
        children: "zones",
        label: "name"
      },
      addForm: {
        parent: -1,
        name: "",
        sort: "",
        folder: 1,
        authority: ""
        // postId: '',
        // remarks: ''
      }, //新增角色
      form: {}, //编辑角色
      menuDetail: [],
      queryData: {
        url: "", //路由
        name: "", //名称
        parent: -1 //父级id
      },
      tableData: [], //人员列表
      grantId: [],
      node_had: [],
      resolve_had: [],
      resolve: [],
      node: [],
      qid: "",
      syData: [],
      postList: [],
      jurisdictionList: [],
      roleGrantMenu: false,
      roleAdd: false,
      roleUpd: false,
      roleDel: false,
    };
  },
  mounted() {
    this.jurisdictionList = JSON.parse(localStorage.getItem("jurisdictionList"));
    this.jurisdictionList.forEach((v) => {
      if (v == "role:grant:menu") {
        this.roleGrantMenu = true;
      }
      if (v == "role:add") {
        this.roleAdd = true;
      }
      if (v == "role:del") {
        this.roleDel = true;
      }
      if (v == "role:upd") {
        this.roleUpd = true;
      }
    });
  },
  methods: {
    addDislog() {
      this.addMenuDislog = true;
      this.getPost();
    },
    getPost() {
      this.$api.getPostListAll().then(res => {
        this.postList = res.data.data;
      });
    },
    menuSort(item) {
      let _that = this;
      _that.qid = item.id;
      _that.requestNewTree();
      _that.menuDetail = item;
      _that.menuProp = true;
    },
    requestNewData() {
      this.node_had.childNodes = []; //把存起来的node的子节点清空，不然会界面会出现重复树！
      this.loadNode(this.node_had, this.resolve_had); //再次执行懒加载的方法
    },
    requestNewTree() {
      this.node.childNodes = []; //把存起来的node的子节点清空，不然会界面会出现重复树！
      this.loadNodeMenu(this.node, this.resolve); //再次执行懒加载的方法
    },
    getList(resolve) {
      let _that = this;
      let id = _that.qid;
      let data = {
        aimId: id,
        parent: -1
      };
      // _that.menuDetail.parent
      _that.$api.menuRole(data).then(res => {
        if (res.data.code == 200) {
          let newData = res.data.data;
          resolve(newData);
        } else {
          _that.utils.error(res.data.message);
        }
      });
    },
    loadNodeMenu(node, resolve) {
      let _that = this;
      _that.defaultCheckedKeys = [];
      if (node.level === 0) {
        this.node = node;
        this.resolve = resolve;
        _that.getList(resolve);
      }
      if (node.level >= 1) {
        let data = {
          aimId: _that.qid,
          parent: node.data.id
        };
        _that.$api.menuRole(data).then(res => {
          if (res.data.code == 200) {
            let oldDate = res.data.data;
            resolve(oldDate);
          } else {
            _that.utils.error(res.data.message);
          }
        });
      }
    },
    // 分配权限
    changeMenuList(e) {
      let _that = this;
      let data = {};
      if (e.hasRole) {
        data = {
          aimId: _that.menuDetail.id,
          targetId: ["add:" + e.id] //新增
        };
      } else {
        data = {
          aimId: _that.menuDetail.id,
          targetId: ["del:" + e.id] //删除
        };
      }
      _that.$api.roleGrant(data).then(res => {
        if (res.data.code == 200) {
          if (e.hasRole) {
            _that.utils.success("新增成功");
          } else {
            _that.utils.success("删除成功");
          }
        } else {
          _that.utils.error(res.data.message);
        }
      });
    },
    handleSearch() {
      this.page = 1;
      // this.getData();
      const _that = this;
      _that.$api.roleList(_that.search).then(res => {
        if (res.data.code == 200) {
          _that.tableData = [];
          const e = res.data.data;
          if (e.length > 0) {
            // _that.tableData.push(res.data.data)
            _that.tableData = e;
          }
        } else {
          _that.utils.error(res.data.message);
        }
      });
    },
    initSearch() {
      this.page = 1;
      this.search = {
        name: "",
        authority: ""
      };
      this.tableData = [];
    },
    //获取菜单详情
    updateData(row) {
      this.editMenuDislog = true;
      let data = {
        parent: row.parent,
        name: row.name,
        sort: row.sort,
        folder: row.folder,
        authority: row.authority,
        id: row.id
      };
      data.folder = data.folder ? 1 : 2;
      this.form = data;
    },
    // 修改菜单
    editMenu() {
      let _that = this;
      if (_that.form.parent == -1 && _that.form.folder == 2) {
        _that.utils.error("该文件不能修改为菜单");
        return;
      }

      let data = {
        name: _that.form.name,
        sort: _that.form.sort,
        folder: _that.form.folder,
        authority: _that.form.authority,
        id: _that.form.id
      };
      if (!data.name) {
        _that.utils.error("请输入角色名称");
        return;
      }
      if (!data.sort) {
        _that.utils.error("请输入排序");
        return;
      }
      if (!data.authority) {
        _that.utils.error("请输入角色权限标识");
        return;
      }
      data.folder = data.folder == 1 ? true : false;
      _that.loading = true;
      this.$api.roleUpdate(data).then(res => {
        if (res.data.code == 200) {
          _that.tableData = [];
          _that.loading = false;
          let e = res.data.data;
          _that.isDel = true;
          _that.requestNewData(); //更新tree视图
          _that.tableData.push({
            parent: e.parent,
            name: e.name,
            sort: e.sort,
            folder: e.folder,
            authority: e.authority,
            id: e.id,
            updateDate: e.updateDate
          }); //更新修改过后的信息

          this.editMenuDislog = false;
          _that.utils.success("修改成功");
        } else {
          this.utils.error(res.data.message);
          _that.fullscreenLoading = false;
          _that.loading = false;
        }
      });
    },
    updataTree(e) {
      let _that = this;
      _that.queryData.parent = e.id;
      _that.isFolder = e.folder;
      _that.tableData = [];
      _that.tableData.push(e);
      let list = [];
      let data = _that.queryData;
      this.$api.menuList(data).then(res => {
        if (res.data.code == 200) {
          list = res.data.data;
          _that.syData = list;
        } else {
          this.utils.error(res.data.message);
        }
      });
    },
    handleCommand(e, item) {
      let _that = this;
      if (e == "add") {
        _that.addMenuDislog = true;
        _that.addForm.id = item.id;
        _that.addForm.parent = item.id;
      } else if (e == "edit") {
        _that.updateData(item);
      } else {
        _that.delMenu(item.id);
      }
    },
    // 删除菜单
    delMenu(id) {
      let _that = this;
      let data = {
        id: id
      };
      this.$confirm("此操作将删除该角色, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error"
      }).then(() => {
        _that.$api.roleDelete(data).then(res => {
          if (res.data.code == 200) {
            _that.tableData = []; //删除成功过后清空角色列表
            _that.isDel = true;
            _that.requestNewData(); //更新tree视图层
            _that.utils.success("删除成功");
          } else {
            _that.utils.error(res.data.message);
          }
        });
      });
    },
    addhandleClose() {
      let _that = this;
      _that.addMenuDislog = false;
      // _that.form =
      _that.addForm = {
        parent: -1,
        name: "",
        sort: "",
        folder: 1,
        authority: ""
      }; //新增角色
    },
    //删除角色
    delData(id) {
      this.delMenu(id);
    },
    // 新增角色
    addMenu() {
      let _that = this;
      let data = _that.addForm;
      if (!data.name) {
        _that.utils.error("请输入角色名称");
        return;
      }

      if (!data.sort) {
        _that.utils.error("请输入排序");
        return;
      }
      if (!data.authority) {
        _that.utils.error("请输入权限标识");
        return;
      }
      data.folder = data.folder == 1 ? true : false;
      _that.loading = true;
      _that.$api.roleOption(data).then(res => {
        if (res.data.code == 200) {
          _that.loading = false;
          _that.addMenuDislog = false;
          _that.isDel = true;
          _that.requestNewData();
          _that.utils.success("新增成功");
          _that.addForm = {
            parent: -1,
            name: "",
            sort: "",
            folder: 1,
            authority: ""
          }; //新增角色
        } else {
          _that.utils.error(res.data.message);
          _that.loading = false;
        }
      });
    },
    // 获取父级部门 tree
    queryDeptList(resolve) {
      let _that = this;
      if (_that.isDel) {
        _that.queryData.parent = -1;
      }
      let data = _that.queryData;
      this.$api.roleList(data).then(res => {
        if (res.data.code == 200) {
          let data = res.data.data;
          _that.isDel = false;
          resolve(data);
        } else {
          this.utils.error(res.data.message);
        }
      });
    },
    loadNode(node, resolve) {
      if (node.level === 0) {
        this.node_had = node; //这里是关键！在data里面定义一个变量，将node.level == 0的node存起来
        this.resolve_had = resolve; //同上，把node.level == 0的resolve也存起来
        this.queryDeptList(resolve); //获取父节点数据
      }
      if (node.level >= 1) {
        this.getChildByList(node.data.id, resolve); //获取子节点数据
        return resolve([]);
      }
    },

    // 获取子级部门 tree
    getChildByList(data, resolve) {
      let _that = this;
      _that.queryData.parent = data;
      let queryData = _that.queryData;
      this.$api.roleList(queryData).then(res => {
        if (res.data.code == 200) {
          let data = res.data.data;
          resolve(data);
        } else {
          this.utils.error(res.data.message);
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
/deep/.el-tree {
  width: 60%;
}
.contant {
  min-height: 500px;

  .leftContant {
    width: 15%;
  }

  .rightContant {
    width: 85%;
  }

  .color_font {
    color: #f39517;
    font-size: 14px;
    cursor: pointer;
  }
}

.dialong-content {
  margin-bottom: 20px;

  .dialong-title {
    margin-bottom: 10px;
  }
}

//.el-button--primary {
//  background-color: #f39517 !important;
//  border-color: #f39517 !important;
//}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
  border: 1px dotted;
}

.avatar {
  width: 120px;
  height: 120px;
  display: block;
}

.hr {
  width: 100%;
  height: 1px;
  background: #ffe2b8;
  margin-top: 30px;
}

.custom-tree-node {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
}

//.contant {
//  margin: 0 30px;
//}

.contantTop {
  margin: 0 30px;
}

.caozuo {
  color: rgb(138, 43, 226);
  margin-right: 20px;
  cursor: pointer;
}

</style>
